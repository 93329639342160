import React, { useState } from "react";
import { Form, Modal, Input, Tooltip, Button } from "antd";
import { MdOutlineInfo } from "react-icons/md";
import "../Supply/SupplyModal.css";
import { useWallet } from "@suiet/wallet-kit";
import toast from "react-hot-toast";
import { UserdepositContract } from "./UserdepositContract";
import USDC from "../../../Assets/Svg/Usdc.svg";
import { GiReceiveMoney } from "react-icons/gi";
import axios from "axios";
import { BaseUrl } from "../../Common/Apis/Apis";

const UserDepositModal = ({
  netWorth,
  userbalances,
  tokenName,
  tokenBalances,
  usdValue,
  Object_Id,
  updatePosition,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calculatedBalance, setCalculatedBalance] = useState("");
  const wallet = useWallet();
  const handleClick = async () => {
    if (!wallet) {
      alert("Please connect your Sui wallet first.");
      return;
    }
    if (calculatedBalance > tokenBalances) {
      toast.error("Entered Value is more then Wallet Balance.");
      return;
    }
    try {
      const result = await UserdepositContract(
        wallet,
        calculatedBalance,
        Object_Id,
        netWorth,
        userbalances,
        tokenName
      );
      updatePosition();
      handleCancel();
      handleOk();
    } catch (error) {
      handleOk();
      console.error("Transaction failed:", error);
    }
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${BaseUrl}client/login`, {
        walletAddress: wallet?.account?.address,
      });
      sessionStorage.setItem("UserAuth", JSON.stringify(response?.data?.data));
    } catch (error) {
      toast.error(
        error?.response?.data?.error || "Login failed. Please try again."
      );
    }
  }

  const showModal = async () => {
    // Check if wallet is connected
    if (!wallet?.connected || !wallet?.account?.address) {
      toast.error("Please connect your wallet to continue");
      return;
    }

    // Get UserAuth from session storage
    const userAuth = sessionStorage.getItem("UserAuth");
    if (!userAuth) {
      wallet.disconnect();
      toast.error("Please reconnect your wallet");
      return;
    }

    // Parse UserAuth and check wallet address match
    try {
      const parsedUserAuth = JSON.parse(userAuth);
      if (parsedUserAuth?.wallet_address !== wallet.account?.address) {
        wallet.disconnect();
        toast.error("Wallet address mismatch. Please reconnect your wallet");
        return;
      }

      // If all checks pass, proceed with opening modal and login
      setIsModalOpen(true);
      setCalculatedBalance("");
      await handleLogin();
    } catch (error) {
      wallet.disconnect();
      toast.error("Invalid session. Please reconnect your wallet");
      return;
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleMaxClick = () => {
    setCalculatedBalance(tokenBalances.toString());
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    setCalculatedBalance(value);
  };

  return (
    <>
      <span
        className="me-2"
        disabled={!wallet.connected && !wallet.account?.address}
        onClick={showModal}
      >
        Deposit
      </span>

      <Modal
        title={`${tokenName} Vault Deposit `}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item>
            <Tooltip title="USDC deposit amount">
              <div className="d-flex align-items-center">
                Amount <MdOutlineInfo className="ms-1" />
              </div>
            </Tooltip>
            <div className="amount-input">
              <div className="p-2 amount-info d-flex justify-content-between">
                <span>
                 
                </span>
                <span>Wallet Balance: {tokenBalances}</span>
              </div>
              <div className="d-flex">
                <Input
                  placeholder="0.00"
                  className="amount-value"
                  type="number"
                  step="any"
                  value={calculatedBalance}
                  onChange={handleInputChange}
                />
                <button
                  className="max-btn"
                  type="button"
                  onClick={handleMaxClick}
                >
                  MAX
                </button>
                <div className="mx-2 d-flex align-items-center">
                  <img
                    src={USDC}
                    alt=""
                    className="img-fluid me-1"
                    width={18}
                  />
                  <strong>{tokenName}</strong>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center"></div>
            </div>
          </Form.Item>
          <Button
            disabled={
              calculatedBalance <= 0 ||
              calculatedBalance > parseFloat(tokenBalances)
            }
            className="supplyBtn w-100 ant-btn-default"
            onClick={handleClick}
          >
            {calculatedBalance <= 0 ? "Enter Amount" : `Deposit ${tokenName}`}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default UserDepositModal;
