import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import { toast } from "react-hot-toast";
import axios from "axios";
import { BaseUrl } from "../../Common/Apis/Apis";

export async function UserdepositContract(
  wallet,
  calculatedBalance,
  Object_Id,
  netWorth,
  userbalances,
  tokenName
) {
  // Check if wallet is connected
  if (!wallet?.connected || !wallet?.account?.address) {
    toast.error("Please connect your wallet to continue");
    return;
  }

  // Get UserAuth from session storage
  const userAuth = sessionStorage.getItem("UserAuth");
  if (!userAuth) {
    wallet.disconnect();
    toast.error("Please reconnect your wallet");
    return;
  }

  // Parse UserAuth and check wallet address match
  try {
    const parsedUserAuth = JSON.parse(userAuth);
    if (parsedUserAuth?.wallet_address !== wallet.account?.address) {
      wallet.disconnect();
      toast.error("Wallet address mismatch. Please reconnect your wallet");
      return;
    }
  } catch (error) {
    wallet.disconnect();
    toast.error("Invalid session. Please reconnect your wallet");
    return;
  }

  try {
    const config = await getConfig();
    const txb = new Transaction();
    const amountInNanoUnits = Math.floor(calculatedBalance * 1e6);
    if (Object_Id?.length > 0) {
      let baseObj = Object_Id[0].coinObjectId;
      
      for (let i = 1; i < Object_Id.length; i++) {
        txb.mergeCoins(baseObj, [Object_Id[i].coinObjectId]);
      }
    }
    const tokenDetails = {
      wUSDC: {
        poolId: pool.USDC.poolId,
        assetId: pool.USDC.assetId,
        type: pool.USDC.type,
      },
      USDC: {
        poolId: pool.NUSDC.poolId,
        assetId: 2,
        type: pool.NUSDC.type,
      },
    };
    const selectedToken = tokenDetails[tokenName];
    const feeConfig = process.env?.REACT_APP_MODE === "DEV" ? process.env?.REACT_APP_DEV_FEE_CONFIG : process.env?.REACT_APP_PROD_FEE_CONFIG;

    if (Object_Id.length > 0) {
      const [coinObject] = txb.splitCoins(txb.object(Object_Id[0].coinObjectId), [
        amountInNanoUnits,
      ]);
      if (tokenName === "wUSDC") {
        txb.moveCall({
          target: `${config.FairmilePackage}::${config.FairmileModule}::userDeposit`,
          arguments: [
            txb.object(config.usdcTreasury),
            coinObject,
            txb.object(feeConfig),
          ],
          typeArguments: [selectedToken.type],
        });
      } else {
        console.error("AAAAAAAA calling for usdc",config.FairmilePackage,config.FairmileModule)
        txb.moveCall({
          target: `${config.FairmilePackage}::${config.FairmileModule}::userDeposit`,
          arguments: [
            txb.object(config.usdcNativeTreasury),
            coinObject,
            txb.object(feeConfig),
          ],
          typeArguments: [selectedToken.type],
        });
      }

    } else {
      throw new Error("No valid coinObjectId found for splitting coins.");
    }
    const result = await wallet.signAndExecuteTransactionBlock({
      transactionBlock: txb,
    });

    // Get fresh UserAuth for API token
    const freshUserAuth = sessionStorage.getItem("UserAuth");
    if (!freshUserAuth) {
      throw new Error("Session expired. Please reconnect your wallet");
    }
    const parsedAuth = JSON.parse(freshUserAuth);

    const apiResponse = await axios.post(
      BaseUrl + "client/depositTransaction",
      {
        walletAddress: wallet.address,
        totalUserPosition: userbalances / 1e6,
        contractNetWorth: netWorth / 1e6,
        usdcAmount: amountInNanoUnits / 1e6,
        TransactionHash: result.digest,
      },
      {
        headers: {
          Authorization: parsedAuth?.token,
          "Content-Type": "application/json",
        },
      }
    );
    toast.success("Transaction successful!");
  } catch (error) {
    toast.error(
      error.message === "[WALLET.SIGN_TX_ERROR] Rejected from user"
        ? "Rejected from user"
        : error.message || "Transaction failed!"
    );
    console.error("Error executing transaction:", error);
  }
}
