import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import { toast } from "react-hot-toast";

export async function deposit(
  wallet,
  calculatedBalance,
  CoinObjectId,
  tokenName,
  fetchTreasureWethBalance,
  fetchTreasureUsdcBalance,
  fetchTreasureBalance
) {
  if (!wallet?.connected || !wallet?.account?.address) {
    toast.error("Please connect your wallet to continue");
    return;
  }

  const userAuth = sessionStorage.getItem("UserAuth");
  if (!userAuth) {
    wallet.disconnect();
    toast.error("Please reconnect your wallet");
    return;
  }

  try {
    const parsedUserAuth = JSON.parse(userAuth);
    if (parsedUserAuth?.wallet_address !== wallet.account?.address) {
      wallet.disconnect();
      toast.error("Wallet address mismatch. Please reconnect your wallet");
      return;
    }
  } catch (error) {
    wallet.disconnect();
    toast.error("Invalid session. Please reconnect your wallet");
    return;
  }

  const config = await getConfig();
  const txb = new Transaction();
  const amountInNanoUnits =
    tokenName === "SUI"
      ? Math.floor(calculatedBalance * 1e9)
      : tokenName === "WETH"
      ? Math.floor(calculatedBalance * 1e8)
      : Math.floor(calculatedBalance * 1e6);
  const tokenDetails = {
    SUI: {
      type: pool.Sui.type,
      coinObjectId: CoinObjectId?.SuiCoinObjectId || "",
    },
    USDC: {
      type: pool.NUSDC.type,
      coinObjectId: CoinObjectId?.NUsdcCoinObjectId || "",
    },
    wUSDC: {
      type: pool.USDC.type,
      coinObjectId: CoinObjectId?.UsdcCoinObjectId || "",
    },
    WETH: {
      type: pool.WETH.type,
      coinObjectId: CoinObjectId?.WethCoinObjectId || "",
    },
  };

  const selectedToken = tokenDetails[tokenName];

  if (tokenName === "SUI") {
    const [coinObject] = txb.splitCoins(txb.gas, [amountInNanoUnits]);
    txb.moveCall({
      target: `${config.FairmilePackage}::${config.FairmileModule}::deposit`,
      arguments: [txb.object(config.suiTreasury), coinObject],
      typeArguments: [selectedToken.type],
    });
  } else if (tokenName === "USDC") {
    if (selectedToken.coinObjectId?.length > 0) {
      let baseObj = selectedToken.coinObjectId[0].coinObjectId;

      for (let i = 1; i < selectedToken.coinObjectId.length; i++) {
        txb.mergeCoins(baseObj, [selectedToken.coinObjectId[i].coinObjectId]);
      }
    }
    if (selectedToken.coinObjectId.length > 0) {
      const [coinObject] = txb.splitCoins(
        txb.object(selectedToken.coinObjectId[0].coinObjectId),
        [amountInNanoUnits]
      );
      txb.moveCall({
        target: `${config.FairmilePackage}::${config.FairmileModule}::deposit`,
        arguments: [txb.object(config.usdcNativeTreasury), coinObject],
        typeArguments: [selectedToken.type],
      });
    } else {
      throw new Error("No valid coinObjectId found for splitting coins.");
    }
  } else if (tokenName === "wUSDC") {
    if (selectedToken.coinObjectId?.length > 0) {
      let baseObj = selectedToken.coinObjectId[0].coinObjectId;

      for (let i = 1; i < selectedToken.coinObjectId.length; i++) {
        txb.mergeCoins(baseObj, [selectedToken.coinObjectId[i].coinObjectId]);
      }
    }
    if (selectedToken.coinObjectId.length > 0) {
      const [coinObject] = txb.splitCoins(
        txb.object(selectedToken.coinObjectId[0].coinObjectId),
        [amountInNanoUnits]
      );
      txb.moveCall({
        target: `${config.FairmilePackage}::${config.FairmileModule}::deposit`,
        arguments: [txb.object(config.usdcTreasury), coinObject],
        typeArguments: [selectedToken.type],
      });
    } else {
      throw new Error("No valid coinObjectId found for splitting coins.");
    }
  } else {
    if (selectedToken.coinObjectId?.length > 0) {
      let baseObj = selectedToken.coinObjectId[0].coinObjectId;

      for (let i = 1; i < selectedToken.coinObjectId.length; i++) {
        txb.mergeCoins(baseObj, [selectedToken.coinObjectId[i].coinObjectId]);
      }
    }
    if (selectedToken.coinObjectId.length > 0) {
      const [coinObject] = txb.splitCoins(
        txb.object(selectedToken.coinObjectId[0].coinObjectId),
        [amountInNanoUnits]
      );
      txb.moveCall({
        target: `${config.FairmilePackage}::${config.FairmileModule}::deposit`,
        arguments: [txb.object(config.wethTreasury), coinObject],
        typeArguments: [selectedToken.type],
      });
    } else {
      throw new Error("No valid coinObjectId found for splitting coins.");
    }
  }

  try {
    const result = await wallet?.signAndExecuteTransactionBlock({
      transactionBlock: txb,
    });
    fetchTreasureWethBalance();
    fetchTreasureUsdcBalance();
    fetchTreasureBalance();
    toast.success("Transaction successful");
  } catch (error) {
    toast.error(
      error.message === "[WALLET.SIGN_TX_ERROR] Rejected from user"
        ? "Rejected by user"
        : error.message || "Transaction failed"
    );
    console.error("Error executing transaction:", error);
  }
}
