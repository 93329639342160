import React from 'react';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import { Container } from 'react-bootstrap';
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Stake = () => {
  return (
    <Container fluid className="bg-light min-vh-100 d-flex align-items-center justify-content-center">
      <Row className="w-100">
        <Col xs={12} sm={10} md={8} lg={8} xl={8} className="mx-auto">
          <Card bordered={false} className="p-4 shadow-sm">
            <h2 className="text-center mb-4">Stake Your Tokens</h2>
            <Form layout="vertical">
              <Form.Item label="Amount to Stake" className="font-weight-bold">
                <Input placeholder="Enter amount" size="large" />
              </Form.Item>
              <Form.Item className="text-center mt-4">
                <Button type="primary" size="large" className="w-100 supplyBtn">
                  Stake
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Stake;