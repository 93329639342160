import {
  Card,
  Col,
  Row,
  Tabs,
  Pagination,
  Select,
  DatePicker,
  Tooltip,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaCopy } from "react-icons/fa";
import toast from "react-hot-toast";
import { useWallet } from "@suiet/wallet-kit";
import { apiToken, BaseUrl } from "../Common/Apis/Apis";
import { IoMdArrowRoundBack } from "react-icons/io";
import moment from "moment/moment";
import { Badge, Table } from "react-bootstrap";
const { Option } = Select;
const { RangePicker } = DatePicker;

const UserTransaction = () => {
  const [allUserDeposit, setAllUserDeposit] = useState([]);
  const [allUserWithdraw, setAllUserWithdraw] = useState([]);
  const [loading, setLoading] = useState(true);
  const [depositPageNumber, setDepositPageNumber] = useState(1);
  const [depositPageSize, setDepositPageSize] = useState(10);
  const [depositTotalTransactions, setDepositTotalTransactions] = useState(0);
  const [withdrawPageNumber, setWithdrawPageNumber] = useState(1);
  const [withdrawPageSize, setWithdrawPageSize] = useState(10);
  const [withdrawTotalTransactions, setWithdrawTotalTransactions] = useState(0);
  const [status, setStatus] = useState("ALL");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const wallet = useWallet();

  const fetchDepositTransactions = async () => {
    setLoading(true);
    try {
      const params = {
        page: depositPageNumber,
        pageSize: depositPageSize,
        status: status === "ALL" ? undefined : status,
        startDate: startDate ? startDate.format("YYYY-MM-DD") : undefined,
        endDate: endDate ? endDate.format("YYYY-MM-DD") : undefined,
      };

      const response = await axios.get(
        `${BaseUrl}client/getDepositTransactionUser`,
        {
          headers: {
            accept: "application/json",
            Authorization: apiToken?.token,
          },
          params,
        }
      );

      const { transactions, totalTransactions } = response.data?.data || {};
      setAllUserDeposit(transactions || []);
      setDepositTotalTransactions(totalTransactions || 0);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching deposit transactions:", error);
      setLoading(false);
    }
  };

  const fetchWithdrawalTransactions = async () => {
    setLoading(true);
    try {
      const params = {
        page: withdrawPageNumber,
        pageSize: withdrawPageSize,
        status: status === "ALL" ? undefined : status,
        startDate: startDate ? startDate.format("YYYY-MM-DD") : undefined,
        endDate: endDate ? endDate.format("YYYY-MM-DD") : undefined,
      };

      const response = await axios.get(
        `${BaseUrl}client/getWithdrawalTransactionOfUser`,
        {
          headers: {
            accept: "application/json",
            Authorization: apiToken?.token,
          },
          params,
        }
      );

      const { transactions, totalTransactions } = response.data?.data || {};
      setAllUserWithdraw(transactions || []);
      setWithdrawTotalTransactions(totalTransactions || 0);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching withdrawal transactions:", error);
      setLoading(false);
    }
  };

  const handleDateChange = (dates) => {
    if (dates) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  useEffect(() => {
    if (wallet?.address) {
      fetchDepositTransactions();
      fetchWithdrawalTransactions();
    }
  }, [
    wallet?.address,
    depositPageNumber,
    depositPageSize,
    withdrawPageNumber,
    withdrawPageSize,
    status,
    startDate,
    endDate,
  ]);

  const formatAddress = (address) => {
    return `${address?.slice(0, 5)}...${address?.slice(-4)}`;
  };

  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address);
    toast.success("Copied");
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleDepositPageChange = (page, pageSize) => {
    setDepositPageNumber(page);
    setDepositPageSize(pageSize);
  };

  const handleWithdrawPageChange = (page, pageSize) => {
    setWithdrawPageNumber(page);
    setWithdrawPageSize(pageSize);
  };

  const transactionTable = (data) => (
    <Table responsive>
      <thead>
        <tr className="text-start">
          <th>Date</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Txid</th>
        </tr>
      </thead>
      <tbody>
        {!loading && data.length > 0 ? (
          data.map((transaction, index) => (
            <tr key={index}>
              <td>
                {moment(transaction?.createdAt).format(
                  "DD/MM/YYYY, hh:mm:ss a"
                )}
              </td>
              <td>
                {transaction?.usdc_amount ||
                  transaction?.usdc_withdrawal_amount}
              </td>
              <td>
                <Badge
                  pill
                  bg={
                    transaction?.status === "APPROVED"
                      ? "success"
                      : transaction?.status === "PENDING"
                      ? "warning"
                      : "danger"
                  }
                >
                  {transaction?.status === "REJECTED"
                    ? "Failed"
                    : transaction?.status === "APPROVED"
                    ? "Complete"
                    : "Pending"}
                </Badge>
              </td>
              <td>
                {transaction?.status === "REJECTED" ? (
                  <Tooltip
                    title={
                      transaction.reject_reason && transaction.reject_reason
                    }
                  >
                    {transaction.reject_reason.slice(0, 20) + "..."}
                  </Tooltip>
                ) : transaction?.status === "PENDING" ? (
                  "Pending..."
                ) : (
                  <>
                    <a
                      target="_blank"
                      href={`https://suiscan.xyz/mainnet/tx/${transaction?.transaction_hash}`}
                      rel="noreferrer"
                    >
                      {formatAddress(transaction?.transaction_hash)}
                    </a>
                    <FaCopy
                      className="text-primary ms-2"
                      onClick={() =>
                        copyToClipboard(transaction?.transaction_hash)
                      }
                    />
                  </>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr className="text-center">
            <td colSpan="5">{loading ? "Loading..." : "No transactions"}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );

  return (
    <div
      className="container-fluid"
      style={{ minHeight: "calc(100vh - 72.84px)", paddingTop: "3rem" }}
    >
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={24} md={22} lg={20} xl={18}>
          <Card>
            <div className="d-flex flex-column flex-md-row">
              <a
                href="javascript: history.go(-1)"
                className="text-decoration-none mb-3 mb-md-0"
              >
                <IoMdArrowRoundBack /> Go Back
              </a>
              <div className="ms-md-auto d-flex flex-column flex-md-row">
                <Select
                  defaultValue="ALL"
                  style={{ width: 200 }}
                  onChange={handleStatusChange}
                  className="mb-3 mb-md-0"
                >
                  <Option value="ALL">All Status</Option>
                  <Option value="PENDING">Pending</Option>
                  <Option value="APPROVED">Complete</Option>
                  <Option value="REJECTED">Failed</Option>
                </Select>
                <RangePicker
                  onChange={handleDateChange}
                  className="mx-md-3 mb-3 mb-md-0"
                />
              </div>
            </div>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Deposit" key="1">
                {transactionTable(allUserDeposit)}
                <div className="d-flex justify-content-center mt-3">
                  <Pagination
                    current={depositPageNumber}
                    pageSize={depositPageSize}
                    onChange={handleDepositPageChange}
                    total={depositTotalTransactions}
                  />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Withdraw" key="2">
                {transactionTable(allUserWithdraw)}
                <div className="d-flex justify-content-center mt-3">
                  <Pagination
                    current={withdrawPageNumber}
                    pageSize={withdrawPageSize}
                    onChange={handleWithdrawPageChange}
                    total={withdrawTotalTransactions}
                  />
                </div>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserTransaction;
