import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Card, Dropdown } from "antd";
import { BsThreeDots } from "react-icons/bs";
import WUSDC from "../../Assets/Svg/wusdc.svg";
import USDC from "../../Assets/Svg/Usdc.svg";
import UserDashboard from "./UserDashboard";
import UserDepositModal from "../../Components/Modal/UserDeposit/UserDeposit";
import axios from "axios";
import {
  apiToken,
  BaseUrl,
  getIndexAssetData,
  RpcUrlSui_getObject,
} from "../../Components/Common/Apis/Apis";
import { getConfig, pool } from "../../Components/Common/Address/Address";
import UserWithdrawModal from "../../Components/Modal/UserWithdral/UserWithdral";
import { useWallet } from "@suiet/wallet-kit";
import SuiWalletConnect from "../../Components/Common/Wallet/Wallet";
import toast from "react-hot-toast";

function User() {
  const [usdcCoinObjectId, setUsdcCoinObjectId] = useState("");
  const [usdcBalance, setUsdcBalance] = useState("0.0");
  const [wusdcCoinObjectId, setWusdcCoinObjectId] = useState("");
  const [wusdcBalance, setWusdcBalance] = useState("0.0");

  const [userbalances, setUserBalances] = useState("0.0");
  const [netWorth, setNetWorth] = useState("0.0");
  const [exchangerate, setExchangerate] = useState("0.0");
  const [assetsInfo, setAssetsInfo] = useState([]);
  const [config, setConfig] = useState(null);
  const [stopDeposit, setStopDeposit] = useState(false);
  const wallet = useWallet();

  const loadConfig = async () => {
    let detail = await getConfig();
    setConfig(detail);
  };

  const fetchBalance = async (coinType, setCoinObjectFunc, setBalanceFunc) => {
    try {
      const response = await axios.post(RpcUrlSui_getObject, {
        jsonrpc: "2.0",
        id: 1,
        method: "suix_getCoins",
        params: [wallet?.address, coinType, null, 50],
      });
      const data = response?.data?.result?.data || [];
      const totalBalance = data.reduce(
        (acc, obj) => acc + parseFloat(obj.balance || "0"),
        0
      );
      setCoinObjectFunc(data || "");
      setBalanceFunc(totalBalance / 1e6);
    } catch (error) {
      console.error("Error fetching balance:", error);
      setBalanceFunc("0.00");
    }
  };

  const fetchNetworth = async () => {
    try {
      const response = await axios.post(RpcUrlSui_getObject, {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          config?.usdcTreasury,
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      setNetWorth(response?.data?.result?.data?.content?.fields?.usdc_amount);
    } catch (error) {
      console.error("Error fetching net worth:", error);
    }
  };

  async function DepositUser() {
    try {
      const response = await axios.get(
        `${BaseUrl}client/getTotalUserPosition`,
        {
          headers: {
            Authorization: apiToken?.token,
          },
        }
      );
      setUserBalances(response.data?.data?.totalUserPositionAmount2 || "0.0");

      // Fetch USDC and wUSDC balances
      fetchBalance(pool?.NUSDC?.type, setUsdcCoinObjectId, setUsdcBalance);
      fetchBalance(pool?.USDC?.type, setWusdcCoinObjectId, setWusdcBalance);
    } catch (error) {
      console.error("Error fetching deposit transactions:", error);
    }
  }

  const fetchTokenInfo = async () => {
    try {
      const response = await axios.get(getIndexAssetData);
      setAssetsInfo(response.data);
    } catch (error) {
      console.error("Error fetching token info:", error);
    }
  };

  const assets = Object.values(assetsInfo)
    .filter((info) => ["USDC"].includes(info.symbol))
    .map((info) => ({
      asset: info.symbol,
      info,
    }));

  useEffect(() => {
    DepositUser();
    fetchBalance(pool?.NUSDC?.type, setUsdcCoinObjectId, setUsdcBalance);
    fetchBalance(pool?.USDC?.type, setWusdcCoinObjectId, setWusdcBalance);
    fetchNetworth();
    fetchTokenInfo();
    loadConfig();
  }, [wallet?.address, wallet.account?.address, apiToken?.token]);

  const DetositClose = () => {
    toast.error(
      "Deposits are currently closed due to the vault reaching its capacity."
    );
  };

  const getAssetActions = (tokenName, tokenBalance, coinObjectId) => {
    return stopDeposit === false
      ? [
          {
            key: "1",
            label: (
              <UserDepositModal
                netWorth={netWorth}
                userbalances={userbalances}
                tokenName={tokenName}
                tokenBalances={tokenBalance}
                usdValue={assets[0]?.info?.price}
                Object_Id={coinObjectId}
                updatePosition={() => DepositUser()}
              />
            ),
          },
          userbalances <= 0
            ? ""
            : {
                key: "2",
                label: (
                  <UserWithdrawModal
                    tokenBalances={userbalances}
                    tokenName={tokenName}
                    usdValue={assets[0]?.info?.price}
                    netWorth={netWorth}
                    updatePosition={() => DepositUser()}
                    exchangerate={exchangerate}
                  />
                ),
              },
        ]
      : [
          {
            key: "3",
            label: <span onClick={DetositClose}>Deposit</span>,
          },
          userbalances <= 0
            ? {
                key: "4",
                label: <span>No Position Available</span>,
              }
            : {
                key: "5",
                label: (
                  <UserWithdrawModal
                    tokenBalances={userbalances}
                    tokenName={tokenName}
                    usdValue={assets[0]?.info?.price}
                    netWorth={netWorth}
                    updatePosition={() => DepositUser()}
                    exchangerate={exchangerate}
                  />
                ),
              },
        ];
  };

  const walletActions = [
    {
      key: "6",
      label: <SuiWalletConnect />,
    },
  ];

  return (
    <div style={{ minHeight: "calc(100vh - 72.84px)" }}>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="mx-auto col-md-6 col-10">
            <UserDashboard
              userbalances={userbalances}
              setStopDeposit={setStopDeposit}
              setExchangerate={setExchangerate}
            />
            <Card title="Wallet Balances" className="my-4">
              <Table>
                <thead>
                  <tr className="text-start">
                    <th>Asset</th>
                    <th>Balance</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-start">
                    <td>
                      <img src={USDC} alt="wUSDC" className="me-2" />
                      <span>USDC (Native)</span>
                    </td>
                    <td>{Number(usdcBalance)?.toFixed(4) || "0.0"}</td>
                    <td>
                      <Dropdown
                        trigger={["click"]}
                        placement="bottom"
                        menu={{
                          items:
                            wallet?.address ||
                            wallet.account?.address ||
                            apiToken?.token
                              ? getAssetActions(
                                  "USDC",
                                  usdcBalance,
                                  usdcCoinObjectId
                                )
                              : walletActions,
                        }}
                      >
                        <BsThreeDots />
                      </Dropdown>
                    </td>
                  </tr>
                  {/* wUSDC */}
                  {/* <tr className="text-start">
                    <td>
                      <img src={WUSDC} alt="USDC" className="me-2" />
                      <span>wUSDC</span>
                    </td>
                    <td>{Number(usdcBalance)?.toFixed(4) || "0.0"}</td>
                    <td>
                      <Dropdown
                        menu={{
                          items:
                            wallet?.address ||
                            wallet.account?.address ||
                            apiToken?.token
                              ? getAssetActions("wUSDC", usdcBalance, usdcCoinObjectId)
                              : walletActions,
                        }}
                      >
                        <BsThreeDots />
                      </Dropdown>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
